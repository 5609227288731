import { SVGProps } from 'react';

function LinkedIn({
  fill = 'var(--neutral-0)',
  width = 20,
  height = 20,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_778_21818"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={21}
        height={20}
      >
        <path d="M22.3335 2H2.3335V22H22.3335V2Z" fill="white" />
      </mask>
      <g mask="url(#mask0_778_21818)">
        <path
          d="M20.8571 2.00049H3.80984C2.99448 2.00049 2.3335 2.66147 2.3335 3.47684V20.5236C2.3335 21.339 2.99448 21.9999 3.80984 21.9999H20.8571C21.6725 21.9999 22.3335 21.339 22.3335 20.5236V3.47684C22.3335 2.66147 21.6725 2.00049 20.8571 2.00049ZM8.29443 19.0383H5.28779V9.48665H8.29443V19.0383ZM6.79111 8.16317C5.83373 8.16317 5.05762 7.4345 5.05762 6.43734C5.05762 5.44019 5.83373 4.71071 6.79111 4.71071C7.74849 4.71071 8.5246 5.44019 8.5246 6.43734C8.5246 7.4345 7.74849 8.16317 6.79111 8.16317ZM19.3792 19.0457H16.3726V13.8287C16.3726 12.2894 15.7182 11.8148 14.8742 11.8148C13.9821 11.8148 13.1073 12.4862 13.1073 13.867V19.0457H10.1007V9.49399H12.9922V10.8173H13.0306C13.3215 10.2293 14.3371 9.22544 15.8884 9.22544C17.5662 9.22544 19.3792 10.2206 19.3792 13.1382V19.0457Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default LinkedIn;
